.modal_center h3{
   color: #000;
}
.modal_center p{
    color: #fff;
}

.modal_center{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  
   
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
    width: 50% !important;
    background-color: #b9d0dd !important;
    box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.08);
border-radius: 10px;
  }
.ReactModal__Content.ReactModal__Content--after-open {
    background-color: #b9d0dd !important;
    border: none !important;
  }
  .modal_center .btn.btn-primary.text-center {
    background-color: #008bd0 !important;
    padding: 7px 24px;
  }

  .modall_img{
    width: 200px;

  }