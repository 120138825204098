
.services__banner{
     
        margin-bottom: 30px;
        background: url("../../assets/img/NewImages/photo-1517245386807-bb43f82c33c4.avif");
        background-repeat: no-repeat;
        background-position: center center;
         background-size: cover;
         height: 350px;
       position: relative;
       border-radius: 25px;
       box-sizing: border-box;

}


.services__banner h1{
position:absolute;
top: 50%;
left: 45%;
}

.pic img{
  background-color: #008bd0;
  padding: 2px;
  width: 55px;
}

.pic img:hover{

  border-radius: 50%;
  transition: all 0.4s;
}

.member{
  background-color: white;

}

.member:hover {
  background-color: #e5f1f8;

}

.member-info{
  padding: 30px;
}

.member-info a{
 text-decoration: none;
 color: #008bd0;
}


.member:hover img {
  border-radius: 50%;
  transition: all 0.4s;
  
}

