.main__hero{
  background-image: url("../../assets/images/organizelawmainbanner.jpg");
  background-size: cover;
  background-position: center;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.hero__div{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hero__div p{
    color: white;
   margin-left: 2px
}



@import 'https://fonts.googleapis.com/css?family=Roboto';


.blink {
  animation: blink 0.5s infinite;
}
@keyframes blink{
  to { opacity: .0; }
}
.flex {
	display: flex;
}
.header-sub-title {
  color: #fff;
  font-family: "Courier";
  font-size: 20px;
  padding: 0.1em;
}





























.testimonial__subdiv {
  box-shadow: 0px 2px 5px;
  height: 300px;
  border-radius: 5px;
}
b.organizemed {
  font-size: 20px;
}
/* 
how we do it************************************* */

.card.resp__card {
  height: 140px;
  background-color: #008bd0;
}

.howwedoit{
  margin-top: 60px;
  background-color: #fff;
  padding-top: 50px;
  padding-bottom: 60px;
}

.howwedoitflex{
display: flex;
justify-content: center;
align-items: center;
margin-top: 20px;

}

.howwedoit__icondiv{
display: flex;
}

.icon__div{
  width: 60px;
}


.section1__contentdiv{
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;

}

.section_span{
    color: white;
    font-weight: 400;
    text-align: center;
}

.sectionp{
    color: white;
    background-color: #3489ce;
    padding: 0px;
    border-radius: 5px;
    width: 150px;
    text-align: center;
}

.section1__contentdiv div{
    border-bottom: 1px solid grey;
}

.number__span{
    font-size: 40px;
    font-weight: 600;

    color: #fff;
}
.card.resp__card h5.card-title {
  color: #fff;
}

.howwedo__icon{
    color:#3489ce;
    max-width: 30px;
}

.resp__card{
  width: 15rem;
  background-color: #3489ce;
 transition: all linear 0.3s;
}

.resp__card:hover{
  color: white;

}

.resp__card:hover p{
  color: white;
}


.resp__card:hover .number__span{
  color: #000;
  
}


/* 
-------------Testimonial section------------- */
.testimonial{
    margin: 50px 0px;
}

.testimonial__heading{
    color: black;
    background-color:  #e9f0ff;


    
}


.testimonial__subdiv{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: -50px;
    
   
}

.testimonial__subdiv p{
    max-width: 200px;
}
.testimonial__image{
    width: 100px;
    height:100px;
    border-radius: 50%;
    border: 5px solid white;
}

.testimonial__icon{
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #008bd0;
  color: #008bd0;
  line-height: 6px;
  padding-top: 8px;
  text-align: center;
}


.testimonial{
  margin: 10px 0px;
}

.testimonial__heading{
  color: black;
  background-color:  #e9f0ff;
 
 
  
}


.testimonial__subdiv{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: -50px;
  
 
}

.testimonial__subdiv p{
  max-width: 200px;
  margin-top: 30px;
}
.testimonial__image{
  width: 100px;
  height:100px;
  border-radius: 50%;
  border: 5px solid white;
}

.testimonial__icon{
  width: 34px;
  height: 34px;
  background-color: #020d25;
  border-radius: 50%;
}




/* 
-------video------------------ */


/* 
------------------doctor-------------------------------------------- */
.doctor{
   background-image: url("../../assets//images/Backgroundimagedoctor.jpg");
   background-repeat: no-repeat;
   background-position: center center;
   background-size: cover;
}
.doctor__subdiv1{
    display: flex;
    gap: 40px;
    flex-direction: column;
    padding: 40px !important;
    color: white;
}

.doctor__subdiv1 span{
  text-decoration: none;
  color: black;
  background-color: white;
  font-size: 16px;
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0px 0px 4px 0px #ffffff;
  font-weight: 400;
}

.doctor__subdiv1__Link{
  text-decoration: none;
  color: white;
  background-color: #3489ce;

  

}




.doctor__subdiv2{
position: relative;
}

.doctor__subdiv2 img {
    position: absolute;
    right: 20%;
    bottom: 0;
    max-width: 220px;
    display: flex;

 
}

/* 
_____________________Featured______________________________________ */

.featured {
    background: #fff;
  }
  .top-head{background-color: #008bd0; width: 50%; color:#fff; text-align: center; padding: 5px 0; font-size:16px;}
  .featured .member {
    margin-bottom: 0px;
    position: relative;
    box-shadow: 0px 0px 1px 2px rgba(74, 74, 74, 0.05);
    transition: ease-in-out 0.3s;
  }
  .featured .member:hover{background: #e5f1f8;}
  .featured .member .pic {
    border-radius: 0px;
    overflow: hidden;
    text-align: center;
    padding: 5px;
  }
  .featured .member img {
    transition: all ease-in-out 0.4s;
  }
  .featured .member:hover img {
    transform: scale(1.1);
  }
  .featured .member h4 {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 18px;
    color: #000;
    position: relative;
  }
  .featured .member-info{padding:10px;}
  .featured .member span {
    font-style: italic;
    display: block;
    font-size: 13px;
    color: #008bd0;
  }
  .featured .member .social {
    margin-top: 10px;
  }
  .featured .member a {
    transition: color 0.3s;
    color: #424242;
    font-size:14px;
    font-weight: 600;
  }
  .featured .member a:hover {
    color: #008bd0;
  }
  .featured .member .social i {
    font-size: 16px;
    margin: 0 2px;
  }

/* 
  ----------------------Sectiobn2 ----------------------------- */
.section2__item{
background-color: #e9f0ff;
padding: 20px;
margin: 15px;

border-radius: 5px;
height: 300px;
}

  
  .section2__icon1{
   width: 48px;
  }


  @media only screen and (max-width:991px){
    .resp__flex{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .howwedoitflex{
      display: flex;
flex-wrap: wrap;
    justify-content: center;
    align-items: center;
      }

    .section1__contentdiv{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .resp__flex img{
      max-width: 200px;
    }


    .video__responsive{
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
/* 
    -----------------------------how we do it------------------------------------------ */

   


    .howwedo__icon{
      display: none;
      
    }

    .resp__card{
     width: 80%;
   
      margin: 15px 50px;
    }
  
    .doctor__subdiv2 img {
      display: none;
   
    
   }

 
   

   
  }

  @media only screen and  (max-width:767px){
    .main__hero h1{
        font-size: 25px;
        font-weight: 600;
        padding-top: -30px;
      
    }

    .resp__card{
      width: 80%;
    
       margin: 15px 150px;
     }

    .doctor__subdiv2 img {
     display: none;
  
   
  }

    .howwedo__icon{
      display: none;
    }

    .main__hero{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .resp__flex img{
      max-width: 200px;
    }

    .video__responsive{
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

   

    .container{
      max-width: 756px;
    }

    .testimonial__subdiv{
        padding: 30px;
    }

    .section2__item{
      background-color: #e9f0ff;
      padding: 20px;
      margin: 15px;
      height: max-content;
      }
  }

  @media only screen and (max-width:576px) {
    .testimonial__subdiv{
      padding: 30px;
  }

  .main__hero img{
    max-width: 200px;
  }

  

  .resp__card{
    width: 80%;


   }
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) { 
    .main__hero{
      height: 235px;
    }
  }
  @media only screen and (max-width:767px) {
    .main__hero{
      height: 270px;
      background-position: unset;
    }
  }
  .footer-newsletter {
    margin-top: 20px;
}

.image-width{
  width: 30px;
 
  padding: 2px;
}

@media only screen and (max-width:767px){
  .resposive__user{
          margin-top: 50px;
  }
}




/* 
-------------------------------------------Other testimonial---------------------------------------------------------------- */

.testhero{
  background-color: white;
   box-shadow: 4px 4px 4px 0px;
}


@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,800);


figure.snip1157 {
font-family: 'Raleway', Arial, sans-serif;
position: relative;
/* overflow: hidden; */
margin: 10px;
min-width: 220px;
max-width: 310px;
width: 100%;
color: white;
text-align: left;
box-shadow: none !important;
}
figure.snip1157 * {
-webkit-box-sizing: border-box;
box-sizing: border-box;
-webkit-transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
margin-top:50px ;
}

figure.snip1157 blockquote {
display: block;
border-radius: 29px;
position: relative;
background-color: #338ad1;
padding: 25px 30px 20px 30px;
font-size: 0.9em;
font-weight: 500;
margin: 0;
line-height: 1.6em;

height: 200px;
}
figure.snip1157 blockquote:before,
figure.snip1157 blockquote:after {
font-family: 'FontAwesome';
content: "\201C";
position: absolute;
font-size: 35px;
opacity: 1.3;
font-style: normal;
}
figure.snip1157 blockquote:before {
top: 25px;
left: 10px;

}
figure.snip1157 blockquote:after {
content: "\201D";
right: 20px;
bottom: 10px;

}
figure.snip1157 .arrow {
top: 100%;
width: 0;
height: 0;
border-left: 0 solid transparent;
border-right: 25px solid transparent;
border-top: 25px solid #338ad1;
margin: 0;
position: absolute;
}
figure.snip1157 .author {
position: absolute;
bottom: -20px;
left: -22%;
padding: 0 10px 0 120px;
margin: 0;
text-transform: uppercase;
color: black;
-webkit-transform: translateY(50%);
transform: translateY(50%);
}



figure.snip1157 .author h5 {
opacity: 0.8;
margin: 0;
font-weight: 600;
font-size: 15px;
}
figure.snip1157 .author h5 span {
font-weight: 400;
text-transform: none;
padding-left: 5px;
}
